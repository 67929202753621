<template>
  <div>
    <svg
      v-if="deviation"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      class="hidden filter"
    >
      <defs>
        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" :stdDeviation="deviation" />
        </filter>
      </defs>
    </svg>
    <v-lazy-image
      :src="props.src"
      :src-placeholder="props.srcPlaceholder"
      v-bind="$attrs"
      :class="[props.imageClass, !deviation ? 'filter-none' : '']"
      @load="animate"
    />
  </div>
</template>

<script setup>
import VLazyImage from "v-lazy-image";

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  imageClass: {
    type: [String, Array, Object],
    required: false,
    default: null,
  },
  srcPlaceholder: {
    type: String,
    required: false,
    default: null,
  },
  blurLevel: {
    type: Number,
    default: 1,
  },
  duration: {
    type: Number,
    default: 5,
  },
});
const rate = ref(1);

const deviation = computed(() => {
  return props.blurLevel * rate.value;
});

function animate() {
  const start = Date.now() + props.duration;

  const step = () => {
    const remaining = start - Date.now();

    if (remaining < 0) {
      rate.value = 0;
    } else {
      rate.value = remaining / props.duration;
      requestAnimationFrame(step);
    }
  };

  requestAnimationFrame(step);
}
</script>

<style scoped>
.filter {
  display: none;
}

.v-lazy-image {
  /* width: 100%;*/
  filter: url("#blur");
}
.v-lazy-image.filter-none {
  /* width: 100%;*/
  filter: none;
}
</style>
